@import-normalize;


/* || General styles */

body {
  margin: 0;
  background: black;
  color: white;
  font-family: 'Courier New', Courier, monospace;
}

th {
  font-size: 2rem;
  text-align: center;
}

td {
  padding: 1em 0;
}

button {
  display: inline-block;
  font-size: 1.3rem;
  font-family: inherit;
  background-color: black;
  color: white;
  padding: .35em;
  border: none;
  cursor: pointer;
}

button:hover {
  background-color: gray;
}

.screen {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: black url("assets/images/bg-start.jpg") center/cover no-repeat fixed;
}

.panel {
  background-color: rgba(25, 25, 25, 0.65);
  padding: 1em;
}

label {
  display: block;
  font-size: 1.3rem;
}

form {
  text-align: left;
}

input {
  font-family: inherit;
  font-size: 1.2rem;
}

hr {
  margin: 1.5em 0;
}

/* || Start screen */

.movement-key {
  border: 1px solid white;
  padding: .25em;
}


/* || End screen */

.high-scores-container {
  margin-top: 1em;
  padding-right: 1em;
  height: 250px;
  overflow-y: auto;
}

.buttons {
  display: flex;
  flex-direction: column;
  max-width: 10em;
  margin: 0 auto;
}

.buttons button {
  margin-bottom: .25em;
}

.graph-container {
  min-height: 100vh;
  background: black url("assets/images/bg-game.jpg") center/cover no-repeat fixed;
}


/* || Login screen */

.form-input {
  margin-bottom: 0.5em;
}
